import { template as template_b9a6285ffb2f48718c278b2fd6e185d5 } from "@ember/template-compiler";
const WelcomeHeader = template_b9a6285ffb2f48718c278b2fd6e185d5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_6136c0500db44135b6c747c8b6696606 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6136c0500db44135b6c747c8b6696606(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
